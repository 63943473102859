import Page, { Title } from "@components/containers/Page";
import { Card, CardContent, CardHeader } from "@components/common";
import React, { useEffect, useState } from "react";
import axios from "@src/common/http";
import "./FundRepresentation.scss";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT } from "@src/common/config";
import { isCancel } from "axios";

const baseClass = "acl-page-fund-representation";

const FundRepresentation: React.FC = () => {
    const [hasNewFunds, setHasNewFunds] = useState<boolean>(true);
    const [hasUpdateDocuments, setHasUpdateDocuments] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        Promise.all([
            axios.get(`${API_ENDPOINT}/rls-wizard`, { params: { isSubmit: false, isCancelled: false } }),
            axios.get(`${API_ENDPOINT}/rls-wizard`, { params: { isSubmit: false, isCancelled: false, isUpdateDocuments: true } }),
        ])
            .then(responses => {
                const { data: newFundsData } = responses[0];
                const { data: updateDocumentsData } = responses[1];
                setHasNewFunds(!!newFundsData);
                setHasUpdateDocuments(!!updateDocumentsData);
            })
            .catch(error => console.error("Error fetching data: ", error));
    }, [hasNewFunds]);

    return (
        <Page>
            <header className={`${baseClass}__header`}>
                <Title className={`${baseClass}__title`}>Fund Representation Switzerland</Title>
            </header>
            <Card>
                <CardHeader>
                    <h3 className={`${baseClass}__bigBold`}>Choose the process</h3>
                </CardHeader>
                <CardContent>
                    <div className={`${baseClass}__actions`}>
                        <Card className={`${baseClass}__action`} onClick={(): void => navigate(`/initiate-onboarding`)}>
                            <CardHeader>
                                <h3 className={`${baseClass}__bigBold`}>{hasNewFunds ? "Continue your work" : "New Funds Onboarding"}</h3>
                            </CardHeader>
                            <CardContent>Initiate onboarding for Swiss representation</CardContent>
                        </Card>
                        <Card className={`${baseClass}__action`} onClick={(): void => navigate(`/update-documents`)}>
                            <CardHeader>
                                <h3 className={`${baseClass}__bigBold`}>{hasUpdateDocuments ? "Continue your work" : "Update fund documents"}</h3>
                            </CardHeader>
                            <CardContent>Provide Acolin with up to date documents such as PR, PRPs, Articles, AR, SAR, etc.</CardContent>
                        </Card>
                    </div>
                </CardContent>
            </Card>
        </Page>
    );
};

export default FundRepresentation;
